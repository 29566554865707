<template>
  <div class="empresa-contabilidad-container">
    <v-container>
      <v-row align="center" justify="space-between">
        <v-col cols="12" md="8">
          <h1>Contabilidad</h1>
          <p>Administra y consulta el plan de cuentas de la empresa.</p>
        </v-col>
      </v-row>

      <v-card class="status-section" outlined>
        <v-card-text>
          <v-alert v-if="isLoading" type="info" dismissible>
            Cargando el plan de cuentas...
          </v-alert>
          <v-alert v-else-if="planDeCuentas.length > 0" type="success" outlined>
            El plan de cuentas está configurado.
          </v-alert>
          <v-alert v-else type="warning" outlined>
            El plan de cuentas no está configurado.
          </v-alert>
        </v-card-text>
      </v-card>

      <v-row class="actions-section" justify="end">
        <v-btn
          color="primary"
          @click="fetchCuentas"
          :loading="isLoading"
          :disabled="isLoading"
        >
          Consultar Plan de Cuentas
        </v-btn>

        <v-btn
          v-if="planDeCuentas.length === 0"
          color="success"
          @click="registrarPlanDeCuentas"
          :loading="isLoading"
          :disabled="isLoading"
        >
          Crear Plan de Cuentas
        </v-btn>
      </v-row>

      <v-row>
        <v-col>
          <CuentasTreeviewDialog
            v-if="planDeCuentas.length > 0"
            :planDeCuentas="planDeCuentas"
            :initialCuenta="null"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  

<script>
import { mapActions, mapState } from "vuex";
import CuentasTreeviewDialog from "@/components/contabilidad/CuentasTreeviewDialog.vue";

export default {
  name: "EmpresaContabilidad",
  components: {
    CuentasTreeviewDialog,
  },
  props: {
    empresa: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false, // Indicador de carga
    };
  },
  computed: {
    ...mapState("contabilidad", {
      planDeCuentas: (state) => state.planDeCuentas,
    }),
  },
  methods: {
    ...mapActions("contabilidad", ["fetchPlanDeCuentas", "crearPlanDeCuentas"]),
    async registrarPlanDeCuentas() {
      try {
        this.isLoading = true;
        const empresaUuid = this.empresa.uuid;

        await this.crearPlanDeCuentas(empresaUuid);
        this.$updateBus.$emit("showSnackBar", {
          text: "Plan de cuentas registrado correctamente.",
          color: "success",
        });
        await this.fetchCuentas(); // Refresca la lista después de crear
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          text: "Error al registrar el plan de cuentas.",
          color: "error",
        });
        console.error("Error al registrar el plan de cuentas:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCuentas() {
      try {
        this.isLoading = true;
        const empresaUuid = this.empresa.uuid;
        await this.fetchPlanDeCuentas({ empresaUuid, isActive: true });
      } catch (error) {
        console.error("Error al obtener el plan de cuentas:", error);
        this.$updateBus.$emit("showSnackBar", {
          text: "Error al obtener el plan de cuentas.",
          color: "info",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.fetchCuentas();
  },
};
</script>

<style lang="scss" scoped>
.empresa-contabilidad-container {
  padding: 20px;

  .status-section {
    margin-bottom: 20px;
  }

  .actions-section {
    margin: 20px 0;
    gap: 10px;
  }

  v-alert {
    margin-bottom: 15px;
  }
}
</style>
